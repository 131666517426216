import { title } from '@/utilities/filters'
import { formatCurrency } from '@/utilities/functions'
import { getFormattedTime } from '@/utilities/org'
import { formatAddress } from '@/utilities/views'

export const headers = (context: Record<string, any>): Record<string, any>[] => {
  return [
    { text: title(String(context.$t('cmac.customerName'))), value: 'customerOrganisationName' },
    {
      renderFunction: (v: Record<string, any>) => formatAddress(v),
      text: title(String(context.$t('common.from'))),
      value: 'from',
    },
    {
      renderFunction: (v: Record<string, any>) => formatAddress(v),
      text: title(String(context.$t('common.to'))),
      value: 'to',
    },
    { text: title(String(context.$t('cmac.vehicleType'))), value: 'vehicleType' },
    {
      renderFunction: (v: string) => getFormattedTime(v),
      text: title(String(context.$t('cmac.startTime'))),
      value: 'startTime',
    },
    {
      renderFunction: (v: string) => getFormattedTime(v),
      text: title(String(context.$t('cmac.endTime'))),
      value: 'endTime',
    },
    { text: title(String(context.$t('common.distance'))), value: 'distance' },
    {
      renderFunction: (v: string | number) => formatCurrency(v),
      text: title(String(context.$t('common.price'))),
      value: 'cost',
    },
    //
  ]
}
