
import { Component, Vue, Watch } from 'vue-property-decorator'
import { headers, items } from '@/pages/fixedPrices/helpers/index'
import { DEFAULT_TABLE_OPTIONS } from '@/utilities/constants'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class DisputesPage extends Vue {
  // data // ***
  options: Record<string, any> = cloneDeep(DEFAULT_TABLE_OPTIONS)
  searchFields: Record<string, string> = {
    customer: '',
    fromAddress: '',
    toAddress: '',
  }

  // computed // ***
  get fixedPrices(): Record<string, any>[] {
    return this.$store.getters['suppliers/fixedPrices']
  }
  get headers(): Record<string, any>[] {
    return headers(this)
  }
  get items(): Record<string, any>[] {
    return items(this)
  }
  get loading(): boolean {
    const { fixedPrices: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get params(): Record<string, any> {
    const { supplierId: SupplierOrganisationIds, searchFields } = this
    const {
      customer: CustomerOrganisationName,
      fromAddress: FromAddress,
      toAddress: ToAddress,
    } = searchFields
    return { CustomerOrganisationName, FromAddress, SupplierOrganisationIds, ToAddress }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get supplierId(): string | undefined {
    return this.supplier?.id
  }
  get urls() {
    return { fixedPrices: 'supplier/fixedJourneyPrices' }
  }

  // created // ***
  created() {
    this.fetch()
  }

  // watch // ***
  @Watch('supplier')
  onSupplierChanged(n: Record<string, any> | undefined, o: Record<string, any> | undefined): void {
    if (n && o?.id !== n.id) this.fetch()
  }

  // methods // ***
  fetch() {
    this.fetchFixedPrices()
  }
  async fetchFixedPrices() {
    const { params, urls } = this
    const url = urls.fixedPrices
    await this.$store.dispatch('suppliers/fetchFixedPrices', { params, url })
  }
  onSearch(): void {
    this.fetch()
  }
  updateOptions(v: Record<string, any>) {
    Vue.set(this, 'options', v)
  }
}
